import { HttpParams } from '@angular/common/http';
import { format } from 'date-fns';

export class Helpers {

  static prepareParams(obj: { [key: string]: any }): HttpParams {
    let params = new HttpParams();

    for (let prop in obj) {
      const value = obj[prop];

      if (Array.isArray(value)) {
        value.forEach(item => params = params.append(prop, item.toString()));
      } else if (value !== null && value !== undefined) {
        params = params.set(prop, value.toString());
      }
    }

    return params;
  }

  static formatDate(date: Date): string {
    return format(date, 'yyyy-MM-dd');
  }

  static copyToClipboard(text: string) {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  static convertUTCtoEET(timeString: Date): string {
    const appointmentDateInEETDate = new Date(timeString);
    const formatter = new Intl.DateTimeFormat('uk-UA', {'timeZone': 'Europe/Kyiv'});
    return formatter.format(appointmentDateInEETDate);
  }

  static toISOStringUTC(date: string | Date, timeSlot: string): string | null {
    if (!date || !timeSlot) {
      console.warn('Invalid date or timeSlot:', { date, timeSlot });
      return null;
    }

    // Ensure timeSlot is properly formatted and extract the first time portion
    const cleanedTimeSlot = timeSlot.trim();
    const shortenedTime = cleanedTimeSlot.includes("-")
      ? cleanedTimeSlot.split("-")[0].trim()
      : cleanedTimeSlot;

    const [hours, minutes] = shortenedTime.split(":").map(Number);

    return new Date(Date.UTC(
      new Date(date).getFullYear(),
      new Date(date).getMonth(),
      new Date(date).getDate(),
      hours,
      minutes
    )).toISOString();
  }
}
