import { StateToken } from "@ngxs/store";
import { IPaginationRes, Service } from "src/app/entities/app.model";
import { Client } from "../../clients/state/clients-state.interface";
import { Employee } from '../../employees/state/employees-state.interface';

export const CALENDAR_STATE_TOKEN = new StateToken<ICalendarState>('CalendarState');

export interface ICalendarState {
  scheduleSlots: IScheduleSlot[];
  appointments: ICalendarAppointments[];
  calendarAppointments: ICalendarAppointments[];
  clientAppointments: ICalendarAppointments[];
  calendarEmployees: CalendarEmployee[];
  isLoading: boolean;
}

export interface IEmployeeScheduleRes extends IPaginationRes {
  data: IWorkingHoursEmployee[];
}

export interface ICalendarAppointmentsRes extends IPaginationRes {
  data: ICalendarAppointments[];
}

export interface ICalendarAppointmentsAddError {
  error_code: number;
  message: string;
}

export interface ICalendarAppointmentsWithErrors {
  data: ICalendarAppointments;
  error: ICalendarAppointmentsAddError;
}

export interface ICalendarAppointments {
  id: string;
  company_id: string;
  branch_id: string;
  client_id: string;
  employee_id: string;
  status: string;
  start_at: Date;
  end_at: Date;
  title?: string;
  price: number;
  duration: number;
  description: string;
  created_at: Date;
  services_sum: number;

  client: Client;
  employee: Employee;

  services: Service[];
}

export interface IWorkingHoursEmployee {
  id: string;
  date: string;
  start_time: string;
  end_time: string;
}

export interface IScheduleSlot {
  start: Date | string;
  end: Date;
  title: string;
}

export interface ISetScheduleSlotBody {
  date: string;
  start_time: string;
  end_time: string;
}

export interface IMonthRange {
  start: string;
  end: string;
}

export interface IGetCalendarAppointments {
  client_id?: string;
  employee_id?: string;
  branch_id?: string;
  start_date?: string;
  range_from?: string;
  range_to?: string;
  status?: string;
  order_by?: string;
  limit?: string;
}

export interface IGetCalendarAppointment {
  appointment_id?: string;
}

export interface CalendarEmployee extends Employee {
  daySchedule: IWorkingHoursEmployee[];
  scheduleTimePeriod: {
    start: string;
    end: string;
  }
}
