import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserDetectionService {

  constructor() {}

  isInAppBrowser(): boolean {
    const userAgent = navigator.userAgent || (window as any).opera;

    // Common in-app browser identifiers (Instagram, Facebook, Messenger, Line, Telegram, TikTok, etc.)
    const inAppIndicators = /Instagram|FBAN|FBAV|FBIOS|FBSV|Messenger|Line|Telegram|Snapchat|TikTok|WeChat/i;
    if (inAppIndicators.test(userAgent)) {
      return true;
    }

    // Android WebView detection: "wv" means WebView (Chrome custom tabs & in-app browsers)
    if (this.isAndroid() && /wv|; wv/i.test(userAgent)) {
      return true;
    }

    // iOS WebView detection: iOS WebViews lack "Safari" in the UA string
    if (this.isiOS() && userAgent.includes('Mobile/') && !userAgent.includes('Safari/')) {
      return true;
    }

    return false;
  }

  isAndroid(): boolean {
    return /Android/i.test(navigator.userAgent);
  }

  isiOS(): boolean {
    return /iPad|iPhone|iPod/i.test(navigator.userAgent);
  }
}
