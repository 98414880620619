import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Branch, Company, BusinessArea, ICompanyResponse, IBranchesRes, IBranchRes, IBranchesResponse, ICompanyRes } from '../state/company-state.interface';
import { map, Observable, of } from 'rxjs';
import { environment } from '@env/environment';
import { Helpers } from '../../../entities/helpers';
import { Store } from '@ngxs/store';
import { UserStateSelectors } from '../../../state/user/user-state.selector';

@Injectable({
  providedIn: 'root'
})
export class CompanyApiService {

  constructor(
    private http: HttpClient,
    private store: Store
  ) { }

  getAllBranches(value): Observable<IBranchesResponse> {
    let company_id = this.store.selectSnapshot(UserStateSelectors.companyId);
    let params = Helpers.prepareParams({ company_id, ...value });

    return this.http.get<IBranchesResponse>(`${environment.apiEndpoint}/branches`, {
      params: params
    });
  }

  getBranch(branchId: string): Observable<Branch> {
    return this.http.get<IBranchRes>(`${environment.apiEndpoint}/branches/${branchId}`)
      .pipe(map((res) => res.data));
  }

  getCompany(): Observable<Company> {
    let companyId = this.store.selectSnapshot(UserStateSelectors.companyId);
    return this.http.get<ICompanyResponse>(`${environment.apiEndpoint}/companies/${companyId}`)
      .pipe(map((res) => res.data));
  }

  getBusinessArea(searchValue: string): Observable<BusinessArea[]> {
    let params = new HttpParams()
      .set('limit', 100)
      .set('search', searchValue);

    return this.http.get<{ data: BusinessArea[] }>(`${environment.apiEndpoint}/business_areas`, { params }).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  createBusinessArea(title: string): Observable<BusinessArea> {
    const body = {title};
    return this.http.post<{ data: BusinessArea }>(`${environment.apiEndpoint}/business_areas`, body)
      .pipe(map((res) => res.data))
  }

  updateBranch(branchId: string, body: any): Observable<Branch> {
    return this.http.put<IBranchRes>(`${environment.apiEndpoint}/branches/${branchId}`, body)
      .pipe(map((res) => res.data));
  }

  addBranch(value: any, companyId: string = null): Observable<Branch> {
    let company_id = this.store.selectSnapshot(UserStateSelectors.companyId) || companyId;
    let body = { company_id, ...value };
    return this.http.post<IBranchRes>(`${environment.apiEndpoint}/branches`, body)
      .pipe(map((res) => res.data));
  }

  deleteBranch(id: string): Observable<any> {
    return this.http.delete(`${environment.apiEndpoint}/branches/${id}`);
  }

  editCompany(body: any): Observable<Company> {
    let companyId = this.store.selectSnapshot(UserStateSelectors.companyId);
    const url = `${environment.apiEndpoint}/companies/${companyId}`;
    return this.http.put<ICompanyRes>(
      url,
      body
    ).pipe(map((res) => res.data));
  }

  createCompany(body: any): Observable<any> {
    const url = `${environment.apiEndpoint}/companies`;
    return this.http.post<any>(
      url,
      body
    ).pipe(map(res => res.data));
  }

  connectBranchToService(branchId: string, serviceId: string) {
    const url = `${environment.apiEndpoint}/branches_to_services`;
    const body = {
      branch_id: branchId,
      service_id: serviceId
    };
    return this.http.post(url, body);
  }

  deleteBranchToService(branchId: string, serviceId: string) {
    const url = `${environment.apiEndpoint}/branches_to_services`;
    const body = {
      branch_id: branchId,
      service_id: serviceId
    };

    return this.http.delete(url, { body });
  }

  connectBranchToEmployee(branchId: string, employeeId: string) {
    const url = `${environment.apiEndpoint}/branches_to_employees`;
    const body = {
      branch_id: branchId,
      employee_id: employeeId
    };
    return this.http.post(url, body);
  }

  deleteBranchToEmployee(branchId: string, employeeId: string) {
    const url = `${environment.apiEndpoint}/branches_to_employees`;
    const body = {
      branch_id: branchId,
      employee_id: employeeId
    };

    return this.http.delete(url, { body });
  }
}
