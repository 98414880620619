export default {
  forms: {
    errors: {
      required: '{{label}} є обов`язковим полем',
      email: 'Введіть коректний email',
      mask: 'Невірно введений номер',
      pattern: 'Невірно введений формат',
      slugInvalid: 'Допускаються тільки латинські букви, цифри і тире (не менше 2 символів)',
      socialInvalid: 'Допускаються тільки латинські букви, цифри, тире, крапки і підкреслення (не менше 2 символів)',
    }
  },
  errors: {
    4010: "Вибачте, але ця дія для вас недоступна.",
    4030: "Ой, запис на цей час неможливий — ми працюємо лише в робочі години.",
    4031: "Схоже, цей час уже заброньований. Оберіть інший, будь ласка!",
    4041: "На жаль, працівник недоступний у цей день. Давайте оберемо іншу дату?",
    4043: "Ці послуги не активні або працівник не надає їх.",
    4060: "Перевищено ліміт плану. Оновіть підписку, щоб продовжити користування!",
    4070: "Оновлення даних цього запису неможливе. Дякуємо за розуміння!",
    4071: "Зміна часу неможлива для скасованих або завершених записів.",
    4072: "Запис створено на минулий час.",
    4080: "У розкладі вже є записи! Не можна все просто так взяти й очистити!",
    4090: "Поле з таким значенням вже існує.",
    4091: "Клієнт із таким номером телефону вже існує.",
    4095: "Працівник із таким номером телефону вже зареєстрований.",
    4096: "Працівник із таким email вже зареєстрований.",
  }
}
