import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, UrlTree, RouterStateSnapshot } from '@angular/router';
import { Store, Actions } from '@ngxs/store';
import { UserStateSelectors } from '../state/user/user-state.selector';
import { Observable } from 'rxjs/internal/Observable';
import { map, of, take, catchError } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InitialRedirectGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
    private actions$: Actions
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(UserStateSelectors.user).pipe(
      // map(() => this.store.selectSnapshot(UserStateSelectors.user)),
      filter(user => !!user),
      take(1),
      map(user => {
        // console.log(`[Guard] User data:`, user);

        if (user?.is_new_user && !state.url.includes('/registration')) {
          // console.log('Redirecting new user to /registration');
          // queueMicrotask(() => this.router.navigate(['/registration']));

          return this.router.createUrlTree(['/registration']);
        }

        if (!user?.is_new_user && state.url.includes('/registration')) {
          // console.log('Redirecting existing user to /calendar');
          return this.router.createUrlTree(['/calendar']);
        }

        return true;
      }),
      catchError(err => {
        console.error('Error fetching user data:', err);
        return of(this.router.createUrlTree(['/not-found']));
      })
    );
  }
}
