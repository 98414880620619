export const environment = {
  production: true,
  env: 'prod',
  baseUrl: `https://admin.natodi.com`,
  apiEndpoint: `https://api.natodi.com/api/v1`,
  widgetEndpoint: `https://book.natodi.com`,
  auth0ClientId: `2OZ1kpyS1zE4HqFbqGEIe1rmFxFHqr2K`,
  audience: `https://dev-4m4cte304ogjb3h3.us.auth0.com/api/v2/`,
  auth0Domain: `auth.natodi.com`,
  amplitudeApiKey: `ddb8c1a1833cebf82672f235d4ab848c`,
  tmpBasePaddleUrl: `https://natodi.com`,
  paddleClientApiToken: 'live_8307461a0e2e20dfa16c3583298',
  paddleInfo: {
    paddleProductIds: {
      free: 'pro_01jk65k82swk2km5dd46abks4p',
      individual: 'pro_01jk65hs6gkjs40vj5eg3280jt',
      team: 'pro_01jk65ff4bfn2evhks5eb205qn',
    },
    monthItems: [
      { // Free
        quantity: 1,
        priceId: 'pri_01jk65n3n431pf9vap9ek1nyeh',
      },
      { // Individual
        quantity: 1,
        priceId: 'pri_01jk65qkh3sqgq1k4r54svqftm',
      },
      { // Team
        quantity: 1,
        priceId: 'pri_01jk65t1hrptgf7rhxsmfgfn06',
      },
    ],
    yearItems: [
      { // Free
        quantity: 1,
        priceId: 'pri_01jk65nwd30gavdtx6h556n2zg',
      },
      { // Individual
        quantity: 1,
        priceId: 'pri_01jk65rke91pbtmfc21vxtxaza',
      },
      { // Team
        quantity: 1,
        priceId: 'pri_01jk65tywbd4jq85a5rs1ee83k',
      },
    ],
  },
  promoCodeUrl: 'https://natodi.notion.site/1b8642bea6f98038bc7ec431526c7ad9',
  infoCenterUrl: 'https://natodi.notion.site/198642bea6f981c09d84e760c601a7b9?v=198642bea6f981eab3e5000c00169e48',
};
