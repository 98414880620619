import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from "@angular/common";

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  standalone: true,
  imports: [
    CommonModule
  ],
})
export class EmptyStateComponent  implements OnInit {
  @Input() icon: string = 'ghost';
  @Input() title: string;
  @Input() description: string;
  @Input() buttonTitle: string;
  @Input() hasButtonIcon: boolean = true;
  @Input() buttonIcon: string = 'plus';
  @Output() onClickButton = new EventEmitter<void>();


  constructor() { }

  ngOnInit() {
  }

}
