import { StateToken } from "@ngxs/store";
import { IPaginationRes } from '../../../entities/app.model';
import { Employee } from "../../employees/state/employees-state.interface";
import { Service } from "../../../entities/app.model";

export const COMPANY_STATE_TOKEN = new StateToken<ICompanyState>('CompanyState');

export interface ICompanyState {
    branches: Branch[];
    branchDetails: Branch;
    company: Company;
    businessAreas: BusinessArea[];
    totalNumberOfBranches: number;
}

export interface ICompanyResponse extends IPaginationRes {
    data: Company;
}

export interface IBranchesResponse extends IPaginationRes {
    data: Branch[];
}

export interface IGetBranchesParams {
    branchId?: string | number;
    search?: string | null;
    limit?: number;
    offset?: number;
}

export interface BusinessArea {
    id: string;
    title: string;
}

export class Company {
    id: string;
    business_area_id: string;
    title: string;
    logo: string;
    phone_number: string;
    email: string;
    site: string;
    instagram: string;
    facebook: string;
    twitter: string;
    tiktok: string;
    branches_sum: number;
    employees_sum: number;
    services_sum: number;
    clients_sum: number;
    appointments_sum: number;
    branches: Branch[];
    business_area: BusinessArea;
}

export interface IBranchesRes extends IPaginationRes {
    data: Branch[];
}

export interface IBranchRes extends IPaginationRes {
    data: Branch;
}

export interface ICompanyRes {
    data: Company;
}

export interface Branch {
    id: string;
    company_id: string;
    title: string;
    slug: string;
    city: string;
    country: string;
    logo: string;
    address: string;
    coordinate_lat: number;
    coordinate_lon: number;
    phone_number: string;
    email: string;
    site: string;
    instagram: string;
    facebook: string;
    twitter: string;
    tiktok: string;
    timezone_name: string;
    currency: string;
    client_language: string;
    is_active: boolean;
    created_at: string;

    employees_sum: number;
    services_sum: number;
    appointments_sum: number;
    clients_sum: number;
    avg_amount_paid_per_appointment: number;
    total_sum_paid: number;

    employees: Employee[];
    services: Service[];
}
