import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { UserStateSelectors } from './state/user/user-state.selector';
import { Observable, filter } from 'rxjs';
import { IUserInfo } from './state/user/user-state.interface';
import { AuthService } from '@auth0/auth0-angular';
import { AmplitudeService } from "./entities/amplitude.service";
import { BrowserDetectionService } from "./entities/browser.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'natodi';

  @Select(UserStateSelectors.user) user$: Observable<IUserInfo>;
  // @Select(CompanyStateSelectors.allBranches) allBranches$: Observable<Branch[]>;

  constructor(
    public auth: AuthService,
    private amplitudeService: AmplitudeService,
    // public profileApiService: ProfileApiService
    private browserDetectionService: BrowserDetectionService,
  ) { }

  ngOnInit() {
    // Detect in-app browser and redirect if necessary
    if (this.browserDetectionService.isInAppBrowser()) {
      this.redirectToDefaultBrowser();
    }

    // Handle user analytics
    this.user$
      .pipe(filter(val => val !== null))
      .subscribe(user => {
        if (user) {
          this.amplitudeService.initAmplitude(user.id);
          this.amplitudeService.setUserIdentifier(user);
        }
      });
  }

  redirectToDefaultBrowser(): void {
    const url = window.location.href;

    if (this.browserDetectionService.isAndroid()) {
      // Redirect to default browser on Android
      window.location.href = `intent://${url.replace(/^https?:\/\//, '')}#Intent;scheme=https;action=android.intent.action.VIEW;end;`;
    } else if (this.browserDetectionService.isiOS()) {
      // Redirect to Safari on iOS
      window.open(url, '_blank');
    } else {
      // Fallback alert
      alert('For the best experience, please open this page in your default browser.');
    }
  }

}
