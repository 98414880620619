import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Employee, IEmployeesRes, Position } from '../state/employees-state.interface';
import { environment } from '@env/environment';
import { Helpers } from '../../../entities/helpers';
import { Store } from '@ngxs/store';
import { UserStateSelectors } from '../../../state/user/user-state.selector';

@Injectable({providedIn: 'root'})
export class EmployeeApiService {

  constructor(
    private http: HttpClient,
    private store: Store
  ) { }

  getEmployees(value: any): Observable<IEmployeesRes> {
    let company_id = this.store.selectSnapshot(UserStateSelectors.companyId);
    let params = Helpers.prepareParams({company_id, ...value});

    return this.http.get<IEmployeesRes>(`${environment.apiEndpoint}/employees`, {
      params: params
    });
  }

  getEmployeeDetails(employeeId: string): Observable<Employee> {
    const url = `${environment.apiEndpoint}/employees/${employeeId}`;
    return this.http.get<{data: Employee}>(url).pipe(map((res) => res.data));
  }

  editEmployee(employeeId: string, body: any): Observable<Employee> {
    const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
    const url = `${environment.apiEndpoint}/employees/${employeeId}`;
    return this.http.put<{data: Employee}>(url, body, {headers}).pipe(map((res) => res.data));
  }

  addEmployee(body: any): Observable<Employee> {
    const url = `${environment.apiEndpoint}/employees`;
    return this.http.post<{data: Employee}>(url, body).pipe(map((res) => res.data));
  }

  deleteEmployee(id: string): Observable<any> {
    const url = `${environment.apiEndpoint}/employees/${id}`;
    return this.http.delete(url);
  }

  connectServiceToEmployee(employeeId: string, serviceId: string) {
    const url = `${environment.apiEndpoint}/employees_to_services`;
    const body = {
      employee_id: employeeId,
      service_id: serviceId
    };
    return this.http.post(url, body);
  }

  deleteServiceToEmployee(employeeId: string, serviceId: string) {
    const url = `${environment.apiEndpoint}/employees_to_services`;
    const body = {
      employee_id: employeeId,
      service_id: serviceId
    };

    return this.http.delete(url, {body});
  }

  getPositions(searchValue?: string, company_id?: string): Observable<Position[]> {
    let companyId = company_id || this.store.selectSnapshot(UserStateSelectors.companyId);
    const url = `${environment.apiEndpoint}/positions`;
    let params = new HttpParams()
      .set('limit', 100)
      .set('search', searchValue)
      .set('company_id', companyId);

    return this.http.get<{ data: Position[] }>(url, { params })
      .pipe(map((res) => {
        return res.data;
      })
    );
  }

  createPosition(title: string): Observable<Position> {
    let companyId = this.store.selectSnapshot(UserStateSelectors.companyId);
    const url = `${environment.apiEndpoint}/positions`;
    const body = {
      title: title,
      ...(companyId && { company_id: companyId })
    };

    return this.http.post<{ data: Position }>(url, body).pipe(map((res) => res.data));
  }
}
