<div class="sidebar" [class.close]="isSidebarClose$ | async">
  <div class="sidebar_logo">
    <i class="icon-logo"></i>
    <i class="icon-logo-text"></i>
  </div>

  <button class="toggle-btn" (click)="toggleSidebar()">
    <i class="icon icon-chevron-left"></i>
  </button>

  <div class="sidebar_main-content">
    <ul class="sidebar_list">
      <li>
        <a
          [routerLink]="Path.CALENDAR"
          class="sidebar_list_item"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <i class="icon-calendar"></i>
          <span class="title">Журнал записів</span>
        </a>
      </li>

      <li>
        <a
          [routerLink]="Path.COMPANY"
          class="sidebar_list_item"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <i class="icon-house"></i>
          <span class="title">Компанія</span>
        </a>
      </li>
      <li>
        <a
          [routerLink]="Path.SERVICES"
          class="sidebar_list_item"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <i class="icon-star"></i>
          <span class="title">Послуги</span>
        </a>
      </li>
      <li>
        <a
          [routerLink]="Path.CLIENTS"
          class="sidebar_list_item"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <i class="icon-id-card"></i>
          <span class="title">Клієнти</span>
        </a>
      </li>
      <li>
        <a
          [routerLink]="Path.EMPLOYEES"
          class="sidebar_list_item"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <i class="icon-people"></i>
          <span class="title">Персонал</span>
        </a>
      </li>
      <li>
        <a
          [routerLink]="Path.SUBSCRIPTIONS"
          class="sidebar_list_item"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <i class="icon-money-bill"></i>
          <span class="title">Підписки</span>
        </a>
      </li>
      <li>
        <a class="sidebar_list_item" (click)="goToInfoCenterPage()">
          <i class="icon-info-outlined"></i>
          <span class="title">Інфоцентр</span>
        </a>
      </li>
      <li>
        <a class="sidebar_list_item" (click)="goToPromoCodePage()">
          <i class="icon-gift-24-outlined"></i>
          <span class="title">Як активувати промокод?</span>
        </a>
      </li>

      <li class="accent"><app-link-follow-button></app-link-follow-button></li>
      <li class="accent">
        <a class="sidebar_list_item" (click)="goToNewAppointmentPage()">
          <i class="icon-plus"></i>
          <span class="title">Додати запис</span>
        </a>
      </li>
    </ul>
  </div>

  <app-profile-menu></app-profile-menu>
</div>
