<div class="profile-menu" *ngIf="user$ | async as user">
  <div class="profile" (click)="toggleMenu()">
    <div class="profile_content">
      <app-avatar
        [firstName]="user.first_name"
        [lastName]="user.last_name"
        [image]="user.avatar"
        size="small"
      ></app-avatar>
      <div class="info">
        <span class="info_name">{{user.first_name}} {{user.last_name}}</span>
        <span class="info_email">{{user.email}}</span>
      </div>
    </div>
    <i class="icon-chevron-bottom"></i>
  </div>
  <div class="dropdown" *ngIf="isOpen">
    <div class="profile-info">
      <span class="subscription-indicator" [ngClass]="user.subscription.type">{{ user.subscription.type }}</span>
      <div class="profile_content" (click)="goToProfile(user.id)">
        <app-avatar
          [firstName]="user.first_name"
          [lastName]="user.last_name"
          [image]="user.avatar"
          size="small"
        ></app-avatar>
        <div class="info">
          <span class="info_name">{{user.first_name}} {{user.last_name}}</span>
          <span class="info_email">{{user.email}}</span>
        </div>
      </div>
    </div>
    <ul class="dropdown_list">
      <li class="dropdown_list__item" (click)="openTelegramBot()">
        <i class="icon-ask-question"></i>
        <span class="title">Підтримка</span>
      </li>
      <li class="dropdown_list__item logout" (click)="logout()">
        <i class="icon-logout"></i>
        <span class="title">Вийти</span>
      </li>
    </ul>
  </div>
</div>
